@mixin light-theme {
    body {
      background-color: $light-bg-color;
      color: $light-text-color;
    }
    .content {
      background-color: $light-bg-color;
      color: $light-text-color;
    }
    strong, span, p, h1, h2, h3, h4, h5, h6 {
      color: $light-text-color;
    }
  
    a,card {
      color: $light-primary-color;
    }
  }
  
  @mixin dark-theme {
    body {
      background-color: $dark-bg-color;
      color: $dark-text-color;
    }
    .content {
      background-color: $dark-bg-color;
      color: $dark-text-color;
    }
    strong, span, p, h1, h2, h3, h4, h5, h6 {
      color: $dark-text-color;
    }
  
    a,card {
      color: $dark-primary-color;
    }
    
  }